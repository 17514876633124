import { gql } from "@apollo/client";

const GLOBAL_QUERY = gql`
  fragment MenuItems on CustomMenu {
    acfMenuItems {
      menu {
        items {
          url
          isExternalLink
          label
          link {
            ... on Page {
              __typename
              uri
            }
            ... on ModelBoard {
              __typename
              uri
            }
          }
        }
      }
    }
  }

  query GLOBAL_QUERY {
    offices(first: 1) {
      nodes {
        acfOffices {
          instagramHandle
          facebookHandle
          twitterHandle
        }
      }
    }
    mainMenu: customMenuBy(uri: "main-menu") {
      ...MenuItems
    }
    footerMenu: customMenuBy(uri: "footer") {
      ...MenuItems
    }
    globalOptions {
      acfGlobalOptions {
        title1
        title2
        models1 {
          ... on Model {
            id
            slug
            title
          }
        }
        models2 {
          ... on Model {
            id
            slug
            title
          }
        }
      }
    }
  }
`

export default GLOBAL_QUERY
