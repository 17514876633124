import { useState, useContext } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { SitesContext } from 'context/SitesContextProvider'
import BOARD_MENU_QUERY from 'graphql/boardMenu.query'
import useDynamicQuery from 'hooks/useDynamicQuery'

import Link from 'components/Link'
import MenuSearch from 'components/MenuSearch'
import Footer from 'components/Footer'
import WordpressLink from 'components/WordpressLink'

import s from './MenuMobile.module.css'

function MenuMobile({
  locations,
  search,
  handleOpenSearch,
  handleOpenBoardMenu,
  handleMenuTitle,
  showChildMenu,
  globalSettings,
  className,
  mainMenu,
  secondaryMenu,
}) {
  const { currentSite } = useContext(SitesContext)
  const router = useRouter()
  const { site } = router.query
  const [activeBoardMenu, setActiveBoardMenu] = useState(null)
  const { data, loading, error } = useDynamicQuery(BOARD_MENU_QUERY, {
    context: { site },
  })

  const boardMenus = data?.modelBoards?.nodes

  const halfSitesArr = Math.ceil(locations?.length / 2)
  const firstSitesArr = locations?.slice(0, halfSitesArr)
  const secondSitesArr = locations?.slice(-halfSitesArr)

  const handleLocationClick = (path) => {
    router.push(
      {
        pathname: '/[site]',
        query: { locationsSplash: true },
      },
      path.replace(/\/$/, '')
    )
  }

  const setLocations = (label = '', sites = []) => {
    if (sites.length < 1) return null
    return (
      <div className={s.region}>
        <h2 className={s.regionName}>{label}</h2>
        {sites.map(({ id, path, name }) => (
          // <Link key={id} className={s.menuLink} type="Site" href={path}>
          //   {name}
          // </Link>
          <button
            key={id}
            type="button"
            className={s.menuLink}
            onClick={() => handleLocationClick(path)}
          >
            {name}
          </button>
        ))}
      </div>
    )
  }

  return (
    <div className={classNames(s.container, className)}>
      {mainMenu && (
        <div className={s.main}>
          <button
            type="button"
            className={s.searchBtn}
            onClick={handleOpenSearch}
          >
            Search
          </button>
          {!showChildMenu && (
            <>
              <div className={s.menu}>
                <h2 className={s.menuTitle}>Select {currentSite?.title}</h2>
                {mainMenu.map((link) => {
                  const linkParts = link.link.uri.split('/')
                  const relatedBoardMenu =
                    linkParts.length < 4 || !boardMenus
                      ? null
                      : boardMenus.find((b) => b.slug === linkParts[1])
                  if (
                    !relatedBoardMenu ||
                    relatedBoardMenu.acfModelBoardParent.departmentOrder
                      .length < 2
                  )
                    return (
                      <WordpressLink
                        key={`${site}_${link.label}`}
                        className={s.menuLink}
                        {...link}
                      />
                    )

                  return (
                    <button
                      type="button"
                      key={`${site}_${link.label}`}
                      className={s.menuLink}
                      onClick={() => {
                        handleOpenBoardMenu(),
                          setActiveBoardMenu(relatedBoardMenu)
                      }}
                    >
                      {link.label}
                    </button>
                  )
                })}
              </div>
              <div className={s.menu}>
                <h2 className={s.menuTitle}>Agency</h2>
                {secondaryMenu.map((link) => (
                  <WordpressLink
                    key={`${site}_${link.label}`}
                    className={s.menuLink}
                    {...link}
                  />
                ))}
              </div>
            </>
          )}
          {!loading && activeBoardMenu && showChildMenu && (
            <div className={s.menu}>
              <h2 className={s.menuTitle}>{activeBoardMenu.title} Boards</h2>
              {activeBoardMenu.acfModelBoardParent.departmentOrder?.map(
                ({ title, slug }) => (
                  <Link
                    key={title}
                    className={s.menuLink}
                    type="Board"
                    href={`${activeBoardMenu.slug}/${slug}`}
                  >
                    {title}
                  </Link>
                )
              )}
            </div>
          )}
        </div>
      )}
      {search && (
        <MenuSearch
          handleMenuTitle={handleMenuTitle}
          globalSettings={globalSettings}
        />
      )}
      {locations.length > 0 && (
        <div className={s.locations}>
          {setLocations('Europe', firstSitesArr)}
          {setLocations('North America', secondSitesArr)}
        </div>
      )}
      {!search && (
        <Footer className={s.footer} globalSettings={globalSettings} />
      )}
    </div>
  )
}

MenuMobile.propTypes = {
  locations: PropTypes.array,
  search: PropTypes.bool,
  handleOpenSearch: PropTypes.func,
  handleOpenBoardMenu: PropTypes.func,
  handleMenuTitle: PropTypes.func,
  showChildMenu: PropTypes.bool,
  globalSettings: PropTypes.object,
  className: PropTypes.string,
}
MenuMobile.defaultProps = {
  locations: [],
  search: false,
  handleOpenSearch: () => {},
  handleOpenBoardMenu: () => {},
  handleMenuTitle: () => {},
  showChildMenu: false,
  globalSettings: {},
  className: '',
}

export default MenuMobile
