import PropTypes from 'prop-types'

import s from './ButtonLocationDesktop.module.css'

function ButtonLocationDesktop({ currentSite, onClick, className }) {
  return (
    <div className={s.container}>
      <button type="button" className={s.btn} onClick={onClick}>
        Select {currentSite}
      </button>
    </div>
  )
}

ButtonLocationDesktop.propTypes = {
  currentSite: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonLocationDesktop.defaultProps = {
  currentSite: 'London',
  onClick: () => {},
  className: '',
}

export default ButtonLocationDesktop
