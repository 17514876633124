import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import matchSorter from 'match-sorter'

import Link from 'components/Link'

import s from './MenuSearchName.module.css'

function MenuSearchName({
  handleOpenMeasurements,
  handleMenuTitle,
  className,
  models,
  loading,
  site,
  globalSettings,
}) {
  const [search, setSearch] = useState('')
  const [results, setResults] = useState(null)
  const [genders, setGenders] = useState([])
  const inputRef = useRef()

  const {
    title1,
    title2,
    models1,
    models2,
  } = globalSettings.globalOptions.acfGlobalOptions

  const handleClearInput = () => {
    setSearch('')
    inputRef.current.focus()
  }

  useEffect(() => {
    if (models) {
      const genders = models.reduce((acc, cur) => {
        if (acc.indexOf(cur.gender) === -1) acc.push(cur.gender)
        if (
          cur.gender !== 'Female' &&
          cur.gender !== 'Male' &&
          cur.gender !== 'Non-binary'
        )
          console.error(
            'This needs to be handled, gender is not set for model: ',
            cur
          )
        return acc
      }, [])
      setGenders(genders)
    }
  }, [models])

  useEffect(() => {
    const searchTerm = search.toLowerCase()
    if (models)
      setResults(
        matchSorter(
          models.filter((m) => m.name.toLowerCase().includes(searchTerm)),
          searchTerm,
          {
            keys: [(item) => item.name, (item) => item.lastName],
          }
        )
      )
  }, [search, loading])

  const renderResults = (res, gender) => (
    <div className={s.col}>
      {gender && <h2 className={s.gender}>{gender}</h2>}
      {res?.slice(0, 10).map(({ slug, name, title }, i) => (
        <Link
          key={`${name || title}${i}`}
          className={s.result}
          href={slug}
          type="Model"
        >
          <span dangerouslySetInnerHTML={{ __html: name || title }} />
        </Link>
      ))}
    </div>
  )

  return (
    <div className={classNames(s.container, className)}>
      <div className={classNames(s.inputWrapper, { [s.loading]: loading })}>
        <input
          ref={inputRef}
          className={s.input}
          placeholder="Name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          autoFocus
        />
        <button
          type="button"
          className={s.inputClearBtn}
          onClick={handleClearInput}
        >
          Clear
        </button>
      </div>
      <button
        type="button"
        className={s.measureBtn}
        onClick={() => {
          handleOpenMeasurements(), handleMenuTitle('Search by measurements')
        }}
      >
        Search by measurements
      </button>
      <div className={s.results}>
        {search === '' && (
          <>
            {renderResults(models1)}
            {renderResults(models2)}
          </>
        )}
        {!loading && results && genders && search !== '' && (
          <>
            {renderResults(results)}
          </>
        )}
      </div>
    </div>
  )
}

MenuSearchName.propTypes = {
  handleOpenMeasurements: PropTypes.func,
  handleMenuTitle: PropTypes.func,
  className: PropTypes.string,
  models: PropTypes.array,
  loading: PropTypes.bool,
  site: PropTypes.string,
  globalSettings: PropTypes.object,
}
MenuSearchName.defaultProps = {
  handleOpenMeasurements: () => {},
  handleMenuTitle: () => {},
  className: '',
  models: [],
  loading: false,
  site: '',
  globalSettings: {},
}

export default MenuSearchName
