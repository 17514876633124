export default {
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://www.selectmodel.com',
    site_name: 'Select Model Management',
  },
  twitter: {
    handle: '@SelectModelMgmt',
    site: '@SelectModelMgmt',
    cardType: 'summary',
  },
};