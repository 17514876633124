import PropTypes from 'prop-types'

import Link from 'components/Link'

import s from './ButtonCollection.module.css'

function ButtonCollection({ colour, className }) {
  return (
    <div className={s.container}>
      <Link href="collections" type="Collections" className={s.btn}>
        <svg
          viewBox="0 0 1190.549 841.891"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={colour}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M642.478,373.742V66.92h-94.407v306.822H241.249v94.406h306.822v306.823h94.407V468.147H949.3v-94.406H642.478z"
          />
        </svg>
      </Link>
      <span className={s.tooltip} />
    </div>
  )
}

ButtonCollection.propTypes = {
  colour: PropTypes.string,
  className: PropTypes.string,
}
ButtonCollection.defaultProps = {
  colour: '#000',
  className: '',
}

export default ButtonCollection
