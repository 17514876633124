import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import WordpressLink from 'components/WordpressLink'

import s from './Menu.module.css'

function Menu({ mainLinks, secLinks, locations, className }) {
  const router = useRouter()

  const handleClick = (path) => {
    router.push(
      {
        pathname: '/[site]',
        query: { locationsSplash: true },
      },
      path.replace(/\/$/, '')
    )
  }

  return (
    <div className={classNames(s.container, className)}>
      {mainLinks.length > 0 && (
        <div className={s.links}>
          {mainLinks.map((l) => (
            <WordpressLink key={l.label} className={s.link} {...l} />
          ))}
        </div>
      )}
      {locations.length > 0 && (
        <div className={s.links}>
          {locations.map(({ name, path }) => (
            <button
              key={name}
              type="button"
              className={classNames(s.link, s.linkBtn)}
              onClick={() => handleClick(path)}
            >
              {name}
            </button>
          ))}
        </div>
      )}
      <div className={s.linksSec}>
        {secLinks.map((l) => (
          <WordpressLink key={l.label} className={s.linkSec} {...l} />
        ))}
      </div>
    </div>
  )
}

Menu.propTypes = {
  mainLinks: PropTypes.array,
  secLinks: PropTypes.array,
  locations: PropTypes.array,
  className: PropTypes.string,
}
Menu.defaultProps = {
  mainLinks: [],
  secLinks: [],
  locations: [],
  className: '',
}

export default Menu
