import { useContext } from 'react'
import dynamic from 'next/dynamic'

import Link from 'components/Link'

import { SitesContext } from 'context/SitesContextProvider'
import { GlobalDispatchContext } from 'context/GlobalContextProvider'
import closeFlashMessage from 'actions/flashMessage/close'

import s from './HardcodedFlashMessage.module.css'
import isModellinkSite from '../../helpers/isModellinkSite';
import { parseCookies } from 'nookies'

function HardcodedFlashMessage() {
  const { currentSite } = useContext(SitesContext)
  const dispatch = useContext(GlobalDispatchContext)

  // NOTE: This slightly annoying as when we build the site, it is possible to hit this code when
  // there isn't a current site. Hence the rather defensive lazy evaluation
  const show = !isModellinkSite() && showFlashMessage()

  return (
    <div>
      {show && (
        <div>
          <div className={s.overlay}></div>
          <div className={s.popup}>
            <h3>Important Notice</h3>
            <p>
              Please be aware that individuals are falsely claiming to work for
              Select Model Management (and they have been using some of our
              staff names and e-mail signatures).
            </p>
            <p>
              If you are contacted by anyone claiming to represent us, please do
              not respond without first verifying their identity by promptly
              calling directly at one of our offices.
            </p>

            <div className={s.buttons}>
              {currentSite && (
                <Link
                  type="Page"
                  className={s.infoButton}
                  href="beware-of-scammers"
                >
                  Find out more
                </Link>
              )}
              <button
                type="button"
                title="Close overlay"
                className={s.continueButton}
                onClick={() => dispatch(closeFlashMessage())}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function showFlashMessage() {
  const cookies = parseCookies()

  if (!cookies['flash-message-read']) {
    return true
  }
  return true
}

export default dynamic(() => Promise.resolve(HardcodedFlashMessage), {
  ssr: false,
})
