import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import classNames from 'classnames'

import s from './Modal.module.css'

const Modal = ({
  className,
  closeClassName,
  isShowing,
  hide,
  children,
  closeBtn,
}) => {
  const modalRef = useRef(null)

  useEffect(() => {
    if (isShowing) {
      const modal = modalRef
      disableBodyScroll(modal.current)
    } else {
      clearAllBodyScrollLocks()
    }

    return () => clearAllBodyScrollLocks()
  }, [isShowing])

  return isShowing
    ? ReactDOM.createPortal(
        <>
          {/* <div className={s.overlay} /> */}
          <div
            ref={modalRef}
            className={classNames(s.modalWrapper, className)}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            {closeBtn === true && (
              <button
                type="button"
                className={classNames(s.closeBtn, closeClassName)}
                data-dismiss="modal"
                aria-label="Close"
                onClick={hide}
              />
            )}
            {children}
          </div>
        </>,
        document.body
      )
    : null
}

Modal.propTypes = {
  className: PropTypes.string,
  closeClassName: PropTypes.string,
  isShowing: PropTypes.bool,
  hide: PropTypes.func,
  closeBtn: PropTypes.bool,
  children: PropTypes.node.isRequired,
}
Modal.defaultProps = {
  className: '',
  closeClassName: '',
  isShowing: false,
  hide: () => {},
  closeBtn: false,
  children: null,
}

export default Modal
