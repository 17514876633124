import { FLASH_MESSAGE_CLEAR } from '@constants'
import { setCookie } from 'nookies'

export default function action() {
  setCookie(null, 'flash-message-read', true, {
    sameSite: 'strict',
    maxAge: 30 * 24 * 60 * 60,
  })
  return { type: FLASH_MESSAGE_CLEAR, payload: {} }
}
