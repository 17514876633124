import PropTypes from 'prop-types'

import s from './ButtonClose.module.css'

function ButtonClose({ colour, onClick, className }) {
  return (
    <button type="button" className={s.container} onClick={onClick}>
      <svg viewBox="0 0 22 19" fill={colour} xmlns="http://www.w3.org/2000/svg">
        <path d="M2.28711 0L21.7415 16.2646L19.4567 18.9975L0.00232184 2.73288L2.28711 0Z" />
        <path d="M0 16.2676L19.4522 0.000360968L21.7373 2.73293L2.28516 19.0001L0 16.2676Z" />
      </svg>
    </button>
  )
}

ButtonClose.propTypes = {
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonClose.defaultProps = {
  colour: '#000',
  onClick: () => {},
  className: '',
}
export default ButtonClose
