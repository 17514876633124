import PropTypes from 'prop-types'

import s from './ButtonLocationMobile.module.css'

function ButtonLocationMobile({ colour, onClick, className }) {
  return (
    <button type="button" className={s.container} onClick={onClick}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          fill={colour}
          fillRule="evenodd"
          d="M9 0C13.9672 0 18 4.03275 18 9C18 13.9672 13.9672 18 9 18C4.03275 18 0 13.9672 0 9C0 4.03275 4.03275 0 9 0ZM10.9178 12H7.0815C7.491 13.845 8.16225 15.0855 9 16.308C9.8955 15.0022 10.5308 13.7505 10.9178 12ZM5.55 12H2.12475C3.05175 14.1187 4.90275 15.729 7.191 16.287C6.405 14.9872 5.85375 13.5435 5.55 12ZM15.8752 12H12.45C12.1568 13.491 11.6287 14.9152 10.8188 16.2705C13.0778 15.6982 14.9565 14.0992 15.8752 12ZM5.3715 7.5H1.65C1.44975 8.48475 1.44975 9.51375 1.65 10.5H5.33625C5.247 9.50325 5.2605 8.496 5.3715 7.5ZM11.1173 7.5H6.882C6.75825 8.4945 6.74325 9.504 6.84225 10.5H11.157C11.2568 9.504 11.2402 8.49525 11.1173 7.5ZM16.35 7.5H12.6278C12.7387 8.496 12.7522 9.50325 12.6637 10.5H16.35C16.545 9.53625 16.5547 8.514 16.35 7.5ZM7.233 1.70325C4.92525 2.25225 3.05775 3.86775 2.12475 6H5.6145C5.94075 4.4685 6.498 3.01125 7.233 1.70325ZM8.99925 1.66725C8.1825 2.99475 7.56675 4.257 7.152 6H10.8472C10.4468 4.3155 9.8445 3.039 8.99925 1.66725ZM10.7752 1.719C11.535 3.078 12.075 4.539 12.3848 6H15.8752C14.9513 3.888 13.0538 2.2815 10.7752 1.719Z"
        />
      </svg>
    </button>
  )
}

ButtonLocationMobile.propTypes = {
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonLocationMobile.defaultProps = {
  colour: '#000',
  onClick: () => {},
  className: '',
}

export default ButtonLocationMobile
