import PropTypes from 'prop-types'

import s from './ButtonMenuDesktop.module.css'

function ButtonMenuDesktop({ colour, onClick, className }) {
  return (
    <button type="button" className={s.container} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1190.549 841.891"
        fill={colour}
      >
        <path d="M234.201 83.943h722.146v96.287H234.201V83.943zM234.201 372.803h722.146v96.286H234.201v-96.286zM234.201 661.661h722.146v96.286H234.201v-96.286z" />
      </svg>
    </button>
  )
}

ButtonMenuDesktop.propTypes = {
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonMenuDesktop.defaultProps = {
  colour: '#000',
  onClick: () => {},
  className: '',
}

export default ButtonMenuDesktop
