import { gql } from "@apollo/client";

const BOARD_MENU_QUERY = gql`
  query BOARD_MENU_QUERY {
    modelBoards {
      nodes {
        title
        slug
        acfModelBoardParent {
          departmentOrder {
            ... on ModelBoard {
              title
              slug
            }
          }
        }
      }
    }
  }
`

export default BOARD_MENU_QUERY
