import { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import ModelListModel from "components/ModelList/ModelListModel";

import s from "./MenuSearchResults.module.css";

function MenuSearchResults({
                             results,
                             searchCriteria,
                             handleUpdateSearch,
                             handleNewSearch,
                             className
                           }) {
  if (!results) return null;
  const hasResults = results.length > 0;

  useEffect(() => {
    const doc = document.documentElement;
    doc.setAttribute("data-results-visible", true);
    return () => {
      doc.removeAttribute("data-results-visible");
    };
  }, []);

  const {
    gender,
    heightMin,
    heightMax,
    bustMin,
    bustMax,
    waistMin,
    waistMax,
    cupMin,
    cupMax,
    hipsMin,
    hipsMax,
    inseamMin,
    inseamMax,
    neckMin,
    neckMax,
    sleeveMin,
    sleeveMax,
    suiteSizeMin,
    suiteSizeMax,
    shoeSizeMin,
    shoeSizeMax
  } = searchCriteria;

  const criteria = [
    {
      label: "Height",
      min: heightMin,
      max: heightMax
    },
    {
      label: "Bust",
      min: bustMin,
      max: bustMax
    },
    {
      label: "Waist",
      min: waistMin,
      max: waistMax
    },
    { label: "Cup", min: cupMin, max: cupMax },
    {
      label: "Hips",
      min: hipsMin,
      max: hipsMax
    },
    {
      label: "Inseam",
      min: inseamMin,
      max: inseamMax
    },
    {
      label: "Neck",
      min: neckMin,
      max: neckMax
    },
    {
      label: "Sleeve",
      min: sleeveMin,
      max: sleeveMax
    },
    {
      label: "Suite Size",
      min: suiteSizeMin,
      max: suiteSizeMax
    },
    {
      label: "Shoe Size",
      min: shoeSizeMin,
      max: shoeSizeMax
    }
  ];

  return (
    <div className={classNames(s.container, className)}>
      <div className={s.results}>
        <h2 className={s.title}>Search Results</h2>
        <div className={s.subtitle}>
          {results.length} {gender !== "non-binary"
          ? gender + "s"
          : gender}
        </div>
        {hasResults > 0 ? (
          <div className={s.measurements}>
            {criteria?.map(({ label, min, max }) => {
              if (!min && !max) return null;
              return (
                <div key={label} className={s.measurement}>
                  <span className={s.measurementLabel}>{label}</span>
                  <span className={s.measurementValue}>
                    {min} &gt; {max}
                  </span>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={s.noItems}>None found</div>
        )}
        <div className={s.btns}>
          <button type="button" className={s.btn} onClick={handleUpdateSearch}>
            Update
          </button>
          <button type="button" className={s.btn} onClick={handleNewSearch}>
            New Search
          </button>
        </div>
      </div>
      <div className={s.modelsWrapper}>
        <div className={s.models}>
          {hasResults &&
            results?.map((result) => (
              <ModelListModel
                key={result.id}
                className={s.model}
                href={result.slug}
                {...result}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

MenuSearchResults.propTypes = {
  results: PropTypes.array,
  searchCriteria: PropTypes.object,
  handleUpdateSearch: PropTypes.func,
  handleNewSearch: PropTypes.func,
  className: PropTypes.string
};
MenuSearchResults.defaultProps = {
  results: [],
  searchCriteria: {},
  handleUpdateSearch: () => {
  },
  handleNewSearch: () => {
  },
  className: ""
};

export default MenuSearchResults;
