import PropTypes from 'prop-types'

import s from './ButtonSearch.module.css'

function ButtonSearch({ colour, onClick, className }) {
  return (
    <button type="button" className={s.container} onClick={onClick}>
      <svg
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1190.549 841.891"
      >
        <path
          fill={colour}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M927.576 708.498l-8.797 8.082 8.64-8.25-174.696-182.865c39.328-51.892 60.659-115.482 60.659-181.433 0-161.696-126.29-293.395-281.831-293.395-155.542 0-281.828 131.698-281.828 293.395 0 161.697 126.286 293.396 281.828 293.396 54.142 0 106.029-15.753 151.246-45.806l176.883 184.82c9.193 9.519 21.178 14.812 33.891 14.812 13.105 0 26.033-5.732 34.259-15.27 17.351-18.038 17.39-48.284-.254-67.486zM531.552 564.022l-9.906-.236C410.017 558.361 321 461.76 321 344.032c0-121.18 94.323-219.944 210.551-219.944 116.223 0 210.503 98.754 210.503 219.944.001 121.197-94.291 219.99-210.502 219.99z"
        />
      </svg>
    </button>
  )
}

ButtonSearch.propTypes = {
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonSearch.defaultProps = {
  colour: '#000',
  onClick: () => {},
  className: '',
}

export default ButtonSearch
