import { useState, useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import graphqlQuery from 'helpers/graphqlQuery'
import SEARCH_QUERY from 'graphql/search.query'
import { SitesContext } from 'context/SitesContextProvider'

import MenuSearchName from './MenuSearchName'
import MenuSearchMeasurements from './MenuSearchMeasurements'
import MenuSearchResults from './MenuSearchResults'

import s from './MenuSearch.module.css'

function MenuSearch({ handleMenuTitle, globalSettings, className }) {
  const router = useRouter()
  const { currentSite } = useContext(SitesContext)
  const [loading, setLoading] = useState(false)
  const [searchCriteria, setSearchCriteria] = useState(null)
  const [models, setModels] = useState(null)
  const [measurements, setMeasurements] = useState(false)
  const [results, setResults] = useState(null)
  const [clearFilters, setClearFilters] = useState(false)

  // todo: add loading and error states/messages
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setModels(null)
      const { models: modelsData } = await graphqlQuery(
        SEARCH_QUERY,
        'solarnet',
        {
          site: router.query.site || currentSite.path.replace(/\//g, ''),
        }
      )
      if (modelsData) setModels(modelsData)
      setLoading(false)
    }
    fetchData()
  }, [router.query.site])

  useEffect(() => {
    setResults(filterModels())
  }, [searchCriteria])

  const handleOpenMeasurements = () => {
    setMeasurements(true)
  }

  const handleCloseMeasurements = () => {
    setMeasurements(false)
  }

  const handleUpdateSearch = () => {
    setResults(null)
  }

  const handleNewSearch = () => {
    setResults(null)
    setClearFilters(true)
  }

  const filterModels = () => {
    if (models?.length < 0 || !searchCriteria) return null

    const searchGender = `${searchCriteria.gender
      .charAt(0)
      .toUpperCase()}${searchCriteria.gender.slice(1)}`
    if (Object.keys(searchCriteria).length === 0)
      return models.filter((m) => m.genders.includes('poo'))

    const suitCutOptions = [
      'X Short',
      'Short',
      'Regular',
      'Long',
      'X Long',
      'XX Long',
    ]
    const cupOptions = ['AA', 'A', 'B', 'B/C', 'C', 'D', 'DD', 'E', 'F']

    const additionalSearchCriteria = [
      {
        attr: 'height',
        min: searchCriteria.heightMin,
        max: searchCriteria.heightMax,
      },
      {
        attr: 'shoes',
        min: searchCriteria.shoeSizeMin,
        max: searchCriteria.shoeSizeMax,
      },
      {
        attr: 'bust',
        min: searchCriteria.bustMin,
        max: searchCriteria.bustMax,
      },
      {
        attr: 'waist',
        min: searchCriteria.waistMin,
        max: searchCriteria.waistMax,
      },
      {
        attr: 'hips',
        min: searchCriteria.hipsMin,
        max: searchCriteria.hipsMax,
      },
      {
        attr: 'inseam_system',
        min: searchCriteria.inseamMin,
        max: searchCriteria.inseamMax,
      },
      {
        attr: 'neck_system',
        min: searchCriteria.neckMin,
        max: searchCriteria.neckMax,
      },
      {
        attr: 'sleeve_system',
        min: searchCriteria.sleeveMin,
        max: searchCriteria.sleeveMax,
      },
      {
        attr: 'suit_size_system',
        min: searchCriteria.suitSizeMin,
        max: searchCriteria.suitSizeMax,
      },
      {
        attr: 'suit_cut_system',
        in: suitCutOptions.slice(
          suitCutOptions.indexOf(searchCriteria.suitCutMin),
          suitCutOptions.indexOf(searchCriteria.suitCutMin) +
            suitCutOptions.indexOf(searchCriteria.suitCutMax)
        ),
      },
      {
        attr: 'cup',
        in: cupOptions.slice(
          cupOptions.indexOf(searchCriteria.cupMin),
          cupOptions.indexOf(searchCriteria.cupMin) +
            cupOptions.indexOf(searchCriteria.cupMax)
        ),
      },
    ].filter((c) => c.in?.length > 0 || c.min || c.max)

    return models.filter((m) => {
      if (m.genders.includes(searchGender) === false) return false
      let valid = true

      additionalSearchCriteria.forEach((criteria) => {
        const measurement = m.measurements.find(
          (msr) => msr.fieldName.toLowerCase() === criteria.attr
        )

        if (!measurement) {
          valid = false
        } else {
          if (criteria.min || criteria.max) {
            const value = parseInt(measurement.fieldValue, 10)
            if (criteria.min && criteria.min > value) valid = false
            if (criteria.max && criteria.max < value) valid = false
          }
          if (criteria.in && !criteria.in.includes(measurement.fieldValue)) {
            valid = false
          }
        }
      })

      return valid
    })
  }

  const handleSubmitMeasurements = (searchCriteria) => {
    setSearchCriteria(searchCriteria)
    setClearFilters(false)
  }

  const sharedProps = {
    models,
    loading,
    site: router.query.site,
  }

  // if (loading)
  //   return (
  //     <div className={s.loading}>
  //       A loading method has been added here. <br />
  //       @abe can we keep the search bar and load the names in after?
  //     </div>
  //   )

  return (
    <div className={classNames(s.container, className)}>
      {!measurements && (
        <MenuSearchName
          models={models}
          handleOpenMeasurements={handleOpenMeasurements}
          handleMenuTitle={handleMenuTitle}
          globalSettings={globalSettings}
          {...sharedProps}
        />
      )}
      {measurements && !results && (
        <MenuSearchMeasurements
          handleCloseMeasurements={handleCloseMeasurements}
          handleMenuTitle={handleMenuTitle}
          handleSubmit={handleSubmitMeasurements}
          clearFilters={clearFilters}
          hide={results?.length > 0}
          {...sharedProps}
        />
      )}
      {results && (
        <MenuSearchResults
          results={results}
          searchCriteria={searchCriteria}
          handleUpdateSearch={handleUpdateSearch}
          handleNewSearch={handleNewSearch}
        />
      )}
    </div>
  )
}

MenuSearch.propTypes = {
  handleMenuTitle: PropTypes.func,
  globalSettings: PropTypes.object.isRequired,
  className: PropTypes.string,
}
MenuSearch.defaultProps = {
  handleMenuTitle: () => {},
  globalSettings: {},
  className: '',
}

export default MenuSearch
