import { gql } from "@apollo/client";

const SEARCH_QUERY = gql`
  query SEARCH_QUERY($site: String) {
    models(site: $site) {
      id
      name
      gender
      genders
      slug
      firstName
      lastName
      image {
        ... on Image {
          sourceUrl
        }
      }
      measurements {
        fieldName
        fieldValue
        fieldValueHuman {
          us
          uk
          metric
        }
      }
    }
  }
`

export default SEARCH_QUERY
