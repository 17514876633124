import PropTypes from 'prop-types'

import s from './ButtonMenuMobile.module.css'

function ButtonMenuMobile({ colour, onClick, className }) {
  return (
    <button type="button" className={s.container} onClick={onClick}>
      <svg viewBox="0 0 36 17" fill={colour} xmlns="http://www.w3.org/2000/svg">
        <path d="M21.4746 4.47363H35.7904V6.26311H21.4746V4.47363Z" />
        <path d="M25.9473 11.6318H35.7894V13.4213H25.9473V11.6318Z" />
        <path d="M16.1053 15.3326L11.908 10.9374C12.6974 9.8005 13.1641 8.40225 13.1641 6.89208C13.1641 3.09187 10.2115 0 6.58172 0C2.95264 0 0 3.09187 0 6.89208C0 10.693 2.95264 13.7842 6.58172 13.7842C7.95759 13.7842 9.23538 13.34 10.2933 12.5814L14.5129 17L16.1053 15.3326V15.3326ZM1.93055 6.89208C1.93055 4.20608 4.01735 2.02087 6.5824 2.02087C9.14744 2.02087 11.2342 4.20608 11.2342 6.89208C11.2342 9.57808 9.14744 11.7633 6.5824 11.7633C4.01668 11.7633 1.93055 9.57808 1.93055 6.89208V6.89208Z" />
      </svg>
    </button>
  )
}

ButtonMenuMobile.propTypes = {
  colour: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}
ButtonMenuMobile.defaultProps = {
  colour: '#000',
  onClick: () => {},
  className: '',
}

export default ButtonMenuMobile
